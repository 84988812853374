.upload {
    @extend %reset;
    width: 100%;
    position: relative;
    z-index: 1;
    @include button(.98);

    &__input {
        display: none;
    }

    &__back {
        @extend %reset;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        pointer-events: none;
    }

    @keyframes runStroke {
        0% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: 100%;
        }
    }

    &:hover .dashedBorder__rect {
        animation: runStroke 7s infinite linear;
    }

    &__content {
        @extend %reset;
        @extend %click;
        @extend %col;
        width: 100%;
        height: 20rem;
        padding: 3rem;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: 2.5rem;
        position: relative;
        z-index: 1;
    }

    &._main &__content {
        height: 17rem;
        background: #f3f3f3;
    }

    &__icon {
        @extend %reset;
        width: 3rem;
        height: 3rem;
        margin-bottom: 2.5rem;
        @include icon($colorDark);

        @include media(0, $mediaM) {
            width: 3.5rem;
            height: 3.5rem;
        }
    }

    &._main &__icon {
        display: none;
    }

    &__description {
        @extend %reset;
        max-width: 100%;
        font-size: 2.1rem;
        line-height: 1.3;
        text-align: center;
        color: $colorDark;

        @include media(0, $mediaM) {
            font-size: 2rem;
        }
    }

    &._main &__description {
        font-size: 2.1rem;
        color: $colorDark;
    }

    &__over {
        @extend %reset;
        @extend %col;
        background: #93D500;
        border-radius: 2.5rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        justify-content: center;
        align-items: center;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__over &__description {
        color: $colorDark;
    }

    &__result {
        @extend %reset;
        @extend %col;
        background: #fff;
        border-radius: 2.5rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        justify-content: center;
        align-items: center;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Files {
            @extend %reset;
            @extend %row;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 1rem;
        }

        &Files &File {
            margin: 0 .5rem 1rem .5rem;
        }

        &File {
            @extend %reset;
            @extend %row;
            align-items: center;
            margin-bottom: 2rem;

            &Name {
                @extend %reset;
                font-size: 1.7rem;
                max-width: 16rem;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                text-overflow: ellipsis;
                color: $colorDark;
                padding: 1rem 1.7rem .9rem 1.7rem;
                font-weight: 500;
                border: .3rem solid $colorDark;
                border-radius: 36rem;
                background: #93D500;

                @include media(0, $mediaM) {
                    font-size: 2.1rem;
                }
            }

            &Button {
                @extend %reset;
                @extend %click;
                margin-left: 1.5rem;
                font-size: 1.9rem;
                color: $colorDark;
                border-bottom: 1px dashed $colorDark;

                @include media(0, $mediaM) {
                    font-size: 2.1rem;
                }
            }
        }

        &Btn {
            @extend %reset;
            @extend %click;
            @extend %row;
            align-items: center;
            font-size: 1.9rem;
            color: $colorDark;

            @include media(0, $mediaM) {
                font-size: 2.1rem;
            }

            &Icon {
                @extend %reset;
                width: 2rem;
                height: 2rem;
                margin-top: -.5rem;
                margin-right: 1rem;
                @include icon($colorDark);

                @include media(0, $mediaM) {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
    }
}