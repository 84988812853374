.page {
    @extend %reset;
    @extend %col;
    width: 100%;
    min-height: 100%;
    // transform: translate(0, -700px);
    background: #A8278e;

    &._inner {
        @extend %innerPage;
    }

    &__section {
        @extend %reset;
        width: 100%;
        position: relative;
        // z-index: 1;

        &._instruction {
            margin-top: -12.5rem;

            @include media(0, $mediaM) {
                margin-top: 0;
            }
        }

        &._prizes {
            @include media(0, $mediaM) {
                margin-top: -3.5rem;
            }
        }

        &._specialPrizes {
            margin-top: -6rem;
        }

        &._footer {
            margin-top: auto;
        }
    }

    &._inner &__section._footer {
        z-index: 3;

        @include media(0, $mediaM) {
            &::before {
                content: "";
                position: absolute;
                top: -7rem;
                left: 0;
                width: 19rem;
                height: 19rem;
                z-index: 1;
                pointer-events: none;
            }
        }
    }
}