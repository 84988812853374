.footer {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 11rem 0 11rem 0;
    background: #B72F9C;

    @include media(0, $mediaM) {
        padding: 9rem 0 9rem 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        align-items: center;
    }

    &__nav {
        @extend %reset;
        @extend %row;
        align-items: center;
        margin-bottom: 6rem;

        @include media(0, $mediaM) {
            flex-direction: column;
            margin-bottom: 7rem;
        }

        &Item {
            @extend %reset;

            &+& {
                margin-left: 5rem;

                @include media(0, $mediaM) {
                    margin-left: 0;
                    margin-top: 7rem;
                }
            }
        }

        &Link {
            @extend %reset;
            @extend %click;
            font-size: 1.9rem;
            font-weight: 600;
            text-transform: uppercase;
            color: #fff;

            @include media(0, $mediaM) {
                font-size: 2.3rem;
                border-bottom: 0;
            }
        }
    }

    &__description {
        @extend %reset;
        max-width: 72%;
        text-align: center;
        font-size: 1.53rem;
        font-family: 400;
        line-height: 1.7;
        color: #fff;

        @include media(0, $mediaM) {
            max-width: 90%;
            font-size: 1.9rem;
        }

        & a {
            @extend %reset;
            @extend %click;
            color: #fff;
        }
    }
}