.mobileMenu {
    @extend %reset;
    @extend %section;
    width: 100%;
    height: 100%;
    background: #A8278E;
    overflow-y: auto;

    &__inner {
        @extend %reset;
        @extend %inner;
        padding: 12rem 0 2.5rem 0;
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
    }

    &__nav {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
        margin-bottom: 6rem;

        &Item {
            @extend %reset;

            &+& {
                margin-top: 4.5rem;
            }
        }

        &Link {
            @extend %reset;
            @extend %click;
            font-size: 2.5rem;
            font-weight: 600;
            text-transform: uppercase;
            color: #fff;
        }
    }

    &__buttons {
        @extend %reset;
        width: 31rem;
    }

    &__button {
        @extend %reset;
        width: 100%;
        display: block;

        &+& {
            margin-top: 3rem;
        }
    }
}