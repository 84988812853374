.button {
    @extend %reset;
    @extend %row;
    @extend %click;
    width: 100%;
    height: 100%;
    border-radius: 36rem;
    padding: 1.8rem 3rem 1.8rem 3rem;
    border: .3rem solid transparent;
    text-transform: uppercase;
    color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include button(.97);
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include media(0, $mediaM) {
        padding: 2.5rem 3.5rem 2.3rem 3.5rem;
    }

    &._mediumSize {
        padding: 1.1rem 1.8rem;

        @include media(0, $mediaM) {
            padding: 1.7rem 2.5rem;
        }
    }

    &._white {
        background: #fff;
        @include loaderColor($colorMain);
    }

    &._white &__content {
        color: $colorMain;
    }

    &._emptyWhite {
        border-color: #fff;
    }

    &._emptyWhite &__content {
        color: #fff;
    }

    &._purple {
        background: #DC3DBC;

        &._dark {
            background: #A8278E;
        }
    }

    &._purple &__content {
        color: #fff;
    }

    &._pink {
        background: #FF59CF;
    }

    &._pink &__content {
        color: #fff;
    }

    // &._purple {
    //     background: #A8278E;
    //     border-color: #A8278E
    // }

    // &._purple &__content {
    //     color: #fff;
    // }

    &._emptyPurple {
        border-color: #A8278E
    }

    &._emptyPurple &__content {
        color: #A8278E;
    }

    &__content {
        @extend %reset;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1.3;
        transition: $trans;

        @include media(0, $mediaM) {
            font-size: 2.6rem;
        }
    }

    &._mediumSize &__content {
        font-size: 2.1rem;

        @include media(0, $mediaM) {
            font-size: 2.45rem;
        }
    }

    &._loader &__content {
        opacity: 0;
    }

    &__loader {
        @extend %reset;
        @extend %loader;
        background: inherit;
        z-index: 100;

        &Item {
            @extend %reset;
            @extend %loaderItem;
            width: 2.5rem;
            height: 2.5rem;
            @include loaderColor(#fff);

            @include media(0, $mediaM) {
                width: 3.5rem;
                height: 3.5rem;
            }
        }
    }

    &._pulsation {
        @keyframes pulsation {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.07);
            }

            100% {
                transform: scale(1);
            }
        }

        animation: pulsation 1.5s infinite ease-in-out;
    }
}