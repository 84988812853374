.table {
    @extend %reset;
    width: 100%;

    &__content {
        @extend %reset;
        width: 100%;
        position: relative;
        border-radius: 0 0 1.5rem 1.5rem;
        background: #fff;
        z-index: 1;
        overflow: hidden;

        @include media(0, $mediaM) {
            border-radius: 1.8rem;
            padding: 2rem;
        }

        &Inner {
            @extend %reset;
            @extend %col;
            width: 100%;
            position: relative;
            transition: $trans;
            z-index: 2;
            overflow: hidden;
        }
    }

    &__loader {
        @extend %reset;
        @extend %loader;
        z-index: 10;

        &Item {
            @extend %reset;
            @extend %loaderItem;
            @include loaderColor($colorDark);
        }
    }

    &__empty {
        @extend %reset;
        @extend %loader;
        z-index: 9;
        justify-content: center;
        align-items: center;
        will-change: transform;
        padding: 3rem;

        &:not(._show) {
            transform: scale(1.05);
        }

        &Inner {
            @extend %reset;
            width: 100%;
        }

        &Title {
            @extend %reset;
            max-width: 100%;
            font-size: 2.3rem;
            font-weight: 500;
            color: $colorDark;
            text-align: center;
            line-height: 1.4;
        }
    }

    ._parentCalc &__rowWrapper,
    &__contentInner._static &__rowWrapper {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__rowWrapper:not(._show) &__row {
        opacity: 0;
    }

    &__row {
        @extend %reset;
        @extend %row;
        width: 100%;
        align-items: stretch;
        transition: $trans;

        &Wrapper {
            @extend %reset;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: $trans;
        }

        @include media(0, $mediaM) {
            justify-content: space-between;
            padding: 1.2rem 1.5rem;
        }

        &._head {
            background: #E1151D;
            height: 7rem;
            border-radius: 1.5rem 1.5rem 0 0;
        }

        &Wrapper._last & {
            // border-radius: 0 0 1.5rem 1.5rem;
        }

        &:not(._head) {
            background: #FFE3F9;

            @include media(0, $mediaM) {
                border-radius: 1.5rem;
            }

            &:not(._odd) {
                background: #fff;
            }
        }

        &Inner {
            @extend %reset;
            @extend %row;
            width: 100%;
            align-items: center;

            &+& {
                margin-top: 1rem;
            }
        }
    }

    &__row._head &__col {
        color: #fff;
    }

    &._winners &__row {
        &:not(._head) {
            @include media(0, $mediaM) {
                background: #fff;

                &._odd {
                    background: #FFE3F9;
                }
            }
        }
    }

    &._winners &__row._head {
        @include media(0, $mediaM) {
            display: none;
        }
    }

    &__row._head &__col {
        @include media(0, $mediaM) {
            padding: 2rem 0;
        }
    }

    &._cheques &__row._head {
        @include media(0, $mediaM) {
            border-radius: 2rem;
        }
    }

    &._cheques &__row._head &__col {
        @include media(0, $mediaM) {
            padding: 0;
        }

        &Wrapper {
            @include media(0, $mediaM) {
                padding: 2rem 0;
            }
        }
    }

    &__col {
        @extend %reset;
        @extend %col;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 2rem;
        line-height: 1.3;
        text-transform: uppercase;
        font-weight: 400;
        padding: 2rem 2rem 2rem 2rem;

        @include media(0, $mediaM) {
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
            text-transform: none;
            padding: 0;
            font-size: 2.3rem;

            &:empty {
                display: none;
            }
        }

        &._head {
            font-weight: 600;
        }

        &Wrapper {
            @extend %reset;
            @extend %row;
            font-size: 2.3rem;
            font-weight: 400;
            line-height: 1.3;
            align-items: stretch;

            @include media(0, $mediaM) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &Inner {
            @extend %reset;
            text-align: center;
            font-weight: 400;
            line-height: 1.3;

            @include media(0, $mediaM) {
                text-align: left;
            }

            & span {
                &._support {
                    color: #DE261F;
                }
            }
        }
    }

    &__colWrapper._main &__col {
        width: 100%;
    }

    &__row._head &__col {
        align-items: center;
        font-weight: 600;
    }

    ._winners &__row,
    &._winners &__row {
        @include media(0, $mediaM) {
            flex-direction: column;
        }
    }

    ._winners &__col,
    &._winners &__col {
        @include media(0, $mediaM) {
            &._head {
                width: 37% !important;
            }

            &:not(._head) {
                width: 63% !important;
            }
        }
    }

    ._winners &__col,
    &._winners &__col {
        align-items: center;

        @include media(0, $mediaM) {
            align-items: flex-start;
        }

        &Wrapper {
            &._main {
                width: 23%;

                @include media(0, $mediaM) {
                    width: 27%;
                }
            }

            &._other {
                width: 77%;

                @include media(0, $mediaM) {
                    width: 73%;
                }
            }
        }

        &._externalId {
            width: 15%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._phone {
            width: 40%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._prize {
            width: 45%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }
    }
}