.checkbox {
    @extend %reset;
    @extend %col;
    width: 100%;

    &__inner {
        @extend %reset;
        @extend %click;
        min-height: 3.5rem;
        display: block;
        position: relative;
        padding-left: 4.5rem;
        @include button(.98);

        @include media(0, $mediaM) {
            padding-left: 5rem;
        }
    }

    &__input {
        display: none;
    }

    &__point {
        @extend %reset;
        @extend %col;
        position: absolute;
        top: -.3rem;
        left: 0;
        width: 3rem;
        height: 3rem;
        background: #FFE3F9;
        border-radius: .6rem;
        transition: $trans;
        z-index: 1;
        overflow: hidden;

        @include media(0, $mediaM) {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: .8rem;
            top: 0;
            transform: translate(0, 0);
        }

        &Icon {
            @extend %reset;
            width: 2rem;
            height: 2rem;
            margin: auto;
            display: block;
            transition: $trans;
            opacity: 0;
            transform: translate(-3rem, 3rem) rotate(-45deg);
            will-change: transform;

            @include media(0, $mediaM) {
                width: 2.2rem;
                height: 2.2rem;
            }
        }
    }

    &._medium &__point {
        background: #f3f3f3;
    }

    &__input:checked~&__point {
        background: #FF59CF;
    }

    &__input:checked~&__point &__pointIcon {
        opacity: 1;
        transform: none;
    }

    &__content {
        @extend %reset;
        font-size: 1.9rem;
        line-height: 1.3;
        color: #fff;

        @include media(0, $mediaM) {
            font-size: 2.2rem;
            padding-top: 0.4rem;
        }

        & a {
            @extend %reset;
            @extend %click;
            color: #fff;
            border-bottom: 1px solid rgba(#fff, .72);
            display: inline-block;

            @include media(0, $mediaM) {
                display: inline;
            }
        }
    }

    &._bold &__content {
        font-weight: 500;
    }

    &._medium &__content {
        color: $colorDark;

        & a {
            color: $colorDark;
            border-bottom: 1px solid rgba($colorDark, .72);
        }
    }
}