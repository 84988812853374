.topBar {
    @extend %reset;
    @extend %section;
    width: 100%;
    height: 100%;
    padding: 2.2rem 0;
    transition: $trans;

    @include media(0, $mediaM) {
        padding: 1rem 0;
    }

    &._active:not(._inner) {
        background: #A8278E;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 100%;
    }

    &__block {
        @extend %reset;

        &._logo {
            @extend %col;
            height: 10rem;
            justify-content: center;

            @include media(0, $mediaM) {
                height: 7rem;
            }
        }

        &._nav {
            @include media(0, $mediaM) {
                display: none;
            }
        }

        &._buttons {
            @include media(0, $mediaM) {
                display: none;
            }
        }
    }

    &__logo {
        @extend %reset;
        @extend %click;
        width: 19.5rem;
        pointer-events: visible;
        transition: $trans;

        &:not(._magnit) {
            transform: translate(-2rem, 0);
            transform-origin: left center;

            .body__topBar._active & {
                transform: translate(-2rem, 0) scale(.7);

                @include media(0, $mediaM) {
                    transform: translate(0, 0);
                }
            }

            @include media(0, $mediaM) {
                transform: translate(0, 0);
            }
        }

        &._magnit {
            width: 23.5rem;
            transform-origin: right center;

            .body__topBar._active & {
                transform: scale(.7);

                @include media(0, $mediaM) {
                    transform: translate(0, 0);
                }
            }

            @include media(0, $mediaM) {
                width: 12.5rem;
            }
        }

        @include media(0, $mediaM) {
            width: 10rem;
        }

        &Icon {
            @extend %reset;
            width: 100%;
            display: block;
        }
    }

    &__nav {
        @extend %reset;
        @extend %row;
        align-items: center;
        margin-left: -1.5rem;

        &Item {
            @extend %reset;
            margin: 0 2rem;
            transition: $trans;

            &:not(._ready) {
                opacity: 0;
                pointer-events: none;
            }
        }

        &Link {
            @extend %reset;
            @extend %click;
            font-size: 1.9rem;
            font-weight: 600;
            color: #fff;
            text-transform: uppercase;
            @include button(.97);
            pointer-events: visible;

            &:hover {
                opacity: 0.64;
            }
        }
    }

    &__buttons {
        @extend %reset;
        @extend %row;
        height: 4.5rem;
        align-items: center;
        position: relative;
        transition: $trans;
    }

    &__buttons._static &__button {
        position: relative;
        margin-left: 1rem;

        &:not(._show) {
            display: none;
        }
    }

    &__button {
        @extend %reset;
        pointer-events: visible;
        position: absolute;
        right: 0;
        top: 0;
        transition: $trans;
        white-space: nowrap;

        &:not(._show) {
            opacity: 0;
        }
    }

    &._active &__button .button._red {
        background: #fff;

        &:hover {
            background: #ececec;
        }
    }

    &._active &__button .button._red .button__content {
        color: $colorMain;
    }

    &._active &__button .button._white {
        background: none;
        border-color: #fff;

        &:hover {
            border-color: #ececec;
        }
    }

    &._active &__button .button._white .button__content {
        color: #fff;
    }

    &._active &__button .button._emptyBlue {
        background: #10CFC9;

        &:hover {
            background: #1CEBE5;
            border-color: #1CEBE5;
        }
    }

    &__menu {
        @extend %reset;
        @extend %col;
        width: 4.5rem;
        height: 2.5rem;
        pointer-events: visible;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: none;

        @include media(0, $mediaM) {
            display: flex;
        }

        &Item {
            @extend %reset;
            @extend %col;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            transition: $trans;
            transform-origin: center;
            will-change: transform;

            &::before {
                content: "";
                width: 100%;
                height: 0.3rem;
                border-radius: 36rem;
                background: #fff;
                margin: auto;
            }

            &:nth-child(1) {
                transform: translate(0, -1rem);
            }

            &:nth-child(3) {
                transform: translate(0, 1rem);
            }
        }

        &._active &Item {
            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
                transform: scale(.3);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }

    &__icon {
        @extend %reset;
        pointer-events: visible;
        width: 4rem;
        height: 4rem;
        object-fit: contain;
        display: none;

        @include media(0, $mediaM) {
            display: block;
        }
    }
}