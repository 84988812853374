.field {
    @extend %reset;
    width: 100%;

    &._main {
        height: 100%;
    }

    &__support {
        @extend %reset;
        font-size: 2.1rem;
        line-height: 1.3;
        font-weight: 600;
        color: #fff;
        margin-bottom: 1rem;

        @include media(0, $mediaM) {
            font-size: 2.5rem;
            margin-bottom: 0.8rem;
        }
    }

    &._medium &__support {
        color: #A8278E;
    }

    &__box {
        @extend %reset;
        width: 100%;
        height: 7rem;
        position: relative;

        @include media(0, $mediaM) {
            height: 8.5rem;
        }
    }

    &._upload &__box {
        height: auto;

        @include media(0, $mediaM) {
            height: auto;
        }
    }

    &._area &__box {
        height: 24rem;

        @include media(0, $mediaM) {
            height: 29rem;
        }
    }

    &._main &__box {
        height: 100%;
    }

    &__input {
        @extend %reset;
        width: 100%;
        height: 100%;
        border-radius: 1.5rem;
        background: #FFE3F9;
        color: $colorDark;
        font-weight: 500;
        font-size: 2.3rem;
        line-height: 1.3;
        padding: 1rem 2rem;

        @include media(0, $mediaM) {
            font-size: 2.4rem;
            border-radius: 1.5rem;
        }
    }

    &._medium &__input {
        border-width: .3rem;
        border-radius: 2rem;
        background: #F3F3F3;
    }

    &._area &__input {
        padding: 2rem;
        border-radius: 1.5rem;
        resize: none;

        @include media(0, $mediaM) {
            border-radius: 1.5rem;
        }
    }

    &._main &__input {
        border-radius: 1.5rem;
        background: #F3F3F3;
        font-size: 2rem;
        padding: 1rem 2rem;

        @include media(0, $mediaM) {
            border-width: .3rem;
        }
    }

    &__list {
        @extend %reset;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        margin-top: 0.5rem;
        z-index: 2;
        width: 100%;
        max-height: 20rem;
        box-shadow: 0 0 1.2rem rgba(#61636A, .15);
        border-radius: 1.5rem;
        background: #fff;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
            transform: translate(0, 1rem);
            pointer-events: none;
        }

        &Inner {
            @extend %reset;
            width: 100%;
            padding: 2rem;
        }

        &Support {
            @extend %reset;
            max-width: 100%;
            font-size: 1.9rem;
            color: $colorDark;
            margin-bottom: 2.5rem;
        }

        &Items {
            @extend %reset;
            width: 100%;
        }

        &Item {
            @extend %reset;
            @extend %click;
            width: 100%;
            font-size: 2.3rem;
            line-height: 1.3;
            color: $colorSub;
            transition: $trans;

            &:hover {
                opacity: .5;
            }

            &+& {
                margin-top: 1.5rem;
            }

            & span {
                color: $colorMain;
            }
        }
    }
}