.faqForm {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 5rem 0 20rem 0;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include media(0, $mediaM) {
        padding: 2rem 0 19rem 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        align-items: center;
        border-radius: 4rem;
        background: #A0007F;
        padding: 8.5rem;

        @include media(0, $mediaM) {
            padding: 5rem 2rem 5rem 2rem;
            border-radius: 3.5rem;
        }
    }

    &__head {
        @extend %reset;
        width: 100%;
        margin-bottom: 5.5rem;

        @include media(0, $mediaM) {
            margin-bottom: 4.5rem;
        }
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;

        &Inner {
            @extend %reset;
            width: 89rem;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }
    }

    &._anket &__content {
        max-width: 88rem;
    }
}