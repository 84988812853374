.error {
    @extend %reset;
    width: 100%;
    height: 100%;
    background: #E1151D;
    color: #fff;
    border-radius: 36rem;
    padding: .8rem 1.5rem .7rem 1.5rem;
    font-size: 1.9rem;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    // white-space: nowrap;

    @include media(0, $mediaM) {
        padding: .7rem 1.5rem .7rem 1.5rem;
        font-size: 2.1rem;
        font-weight: 600;
    }
}