.body {
    @extend %reset;
    @extend %noscroll;
    width: 100%;
    -webkit-text-size-adjust: none;
    position: relative;
    z-index: 1;

    &._selectNode {
        user-select: none;

        * {
            user-select: none;
        }
    }

    @include media(0, $mediaM) {
        overflow: hidden;
    }

    &__inner {
        @extend %reset;
        @extend %col;
        @extend %noscroll;
        width: 100%;
        height: 100vh;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &:not(._init) {
            transition: $trans;
        }
    }

    &__main {
        @extend %reset;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    &__topBar {
        @extend %reset;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        height: 15rem;
        pointer-events: none;
        transition: $trans;

        @include media(0, $mediaM) {
            height: auto;
        }

        &._active {
            height: 9rem;

            @include media(0, $mediaM) {
                height: auto;
            }
        }
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        position: relative;
        height: 100vh;
        z-index: 2;
        overflow: hidden;

        &Inner {
            @extend %reset;
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
        }
    }

    &__inner:not(._init) &__main,
    &__inner:not(._init) &__content {
        transition: $trans;
    }

    &__pages {
        @extend %reset;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    &__popupBack {
        @extend %reset;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        background: rgba(#3E0032, .5);
        transition: $trans;

        &:not(._show) {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__page {
        @extend %reset;
        @extend %noscroll;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        overflow-y: scroll;
        transition: $trans;

        &._popup {
            z-index: 1001;
            position: fixed;
        }

        &:not(._show) {
            pointer-events: none;
            opacity: 0;

            &._popup {
                transform: scale(1.02);
            }
        }
    }

    &__popup {
        @extend %reset;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1001;
        overflow-y: auto;
        transition: $trans;

        &:not(._show) {
            pointer-events: none;
            opacity: 0;
            transform: scale(1.02);
        }
    }

    &__mobileMenu {
        @extend %reset;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            // opacity: 0;
            transform: translate(-100%, 0);
            pointer-events: none;
        }
    }

    &__cookies {
        @extend %reset;
        position: fixed;
        width: 140rem;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 2.5rem;
        z-index: 998;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
            transform: translate(-50%, 2rem);
            pointer-events: none;
        }

        @include media(0, $mediaM) {
            width: 93vw;
        }
    }
}