.innerPageHead {
    @extend %reset;
    @extend %col;
    width: 100%;
    align-items: center;

    &._outside {
        padding-bottom: 5rem;

        @include media(0, $mediaM) {
            padding: 0 3rem 3rem 3rem;
        }
    }

    &__title {
        @extend %title;
        text-align: center;
    }

    &__title+&__description {
        margin-top: 1.5rem;

        @include media(0, $mediaM) {
            margin-top: 2rem;
        }
    }

    &__description {
        @extend %title;
        text-align: center;

        @include media(0, $mediaM) {
            font-size: 3.2rem;
            line-height: 1.3;
        }
    }

    &__alert {
        @extend %reset;
        color: #A82185;
        background: #FED900;
        border-radius: .6rem;
        padding: .7rem 1.7rem;
        font-size: 1.85rem;
        font-weight: 600;
        margin: 4rem 0 0 0;

        @include media(0, $mediaM) {
            padding: 1.3rem 1.5rem;
            font-size: 2rem;
        }
    }

    &__button {
        @extend %reset;
        width: 35rem;
        margin-top: 3rem;

        @include media(0, $mediaM) {
            width: 28rem;
            margin-top: 4rem;
        }
    }
}