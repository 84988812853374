.popup {
    @extend %reset;
    @extend %noscroll;
    @extend %col;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 5.5rem 0;

    @include media(0, $mediaM) {
        padding: 9rem 0 4rem 0;
    }

    &__inner {
        @extend %reset;
        margin: auto;
        width: 72rem;
        // height: 73.5rem;
        position: relative;

        @include media(0, $mediaM) {
            width: 92vw;
            margin: 0 auto;
        }

        &Box {
            @extend %reset;
            @extend %col;
            width: 100%;
            height: 100%;
            align-items: center;
            background: #fff;
            border-radius: 3rem;
            position: relative;
            z-index: 1;
            overflow: hidden;
            padding: 4rem 5rem;

            @include media(0, $mediaM) {
                border-radius: 2rem;
                padding: 3rem 1.8rem 4rem 1.8rem;
            }
        }
    }

    &._specialPrizeInfoPopup &__inner {
        margin: auto;
    }

    &._specialPrizeInfoPopup &__innerBox {
        padding: 3rem 1.8rem 3rem 1.8rem;
    }

    &._orderPopup &__inner,
    &._gamePopup &__inner,
    &._soundsPopup &__inner {
        width: 68rem;

        @include media(0, $mediaM) {
            width: 92vw;
        }
    }

    &__close {
        @extend %reset;
        @extend %click;
        @extend %col;
        position: absolute;
        top: 0;
        left: 100%;
        margin: 0 0 0rem 1rem;
        width: 6rem;
        height: 6rem;
        border-radius: 1.8rem;
        background: #FF59CF;

        @include media(0, $mediaM) {
            width: 4.5rem;
            height: 4.5rem;
            margin: 0 0 1rem 0;
            border-radius: 1.3rem;
            top: auto;
            bottom: 100%;
            left: auto;
            right: 0;
        }

        &Icon {
            @extend %reset;
            width: 2rem;
            height: 2rem;
            @include icon(#fff);
            margin: auto;
            display: block;
        }
    }

    &__blocks {
        @extend %reset;
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__block {
        @extend %reset;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-10rem, -50%);
            }

            &._next {
                transform: translate(10rem, -50%);
            }
        }
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__head {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
        margin-bottom: 4rem;

        @include media(0, $mediaM) {
            margin-bottom: 2.5rem;
        }

        &Title {
            @extend %title;
            color: #A8278E;
            text-align: center;

            &._block {
                background: #DC3DBC;
                color: #fff;
                font-size: 3.6rem;
                max-width: 100%;
                text-align: center;
                padding: .5rem 1.5rem;
                border-radius: 1rem;
            }

            @include media(0, $mediaM) {
                line-height: 1.1;
            }
        }

        &Description {
            @extend %reset;
            max-width: 100%;
            font-size: 1.95rem;
            line-height: 1.3;
            font-weight: 400;
            color: $colorDark;
            text-align: center;
            margin-top: 1rem;

            @include media(0, $mediaM) {
                font-size: 2.1rem;
                margin-top: 1rem;
            }
        }
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;

        &._minHeight {
            justify-content: center;
            min-height: 25rem;
        }
    }

    &__fields {
        @extend %reset;
        width: 100%;
        margin-bottom: 2rem;

        @include media(0, $mediaM) {
            margin-bottom: 2.5rem;
        }
    }

    &__field {
        @extend %reset;
        @extend %col;
        width: 100%;

        &+& {
            margin-top: 3rem;

            @include media(0, $mediaM) {
                margin-top: 1.8rem;
            }
        }

        &Link {
            @extend %reset;
            @extend %click;
            margin: 1rem 0 0 auto;
            font-size: 1.9rem;
            font-weight: 600;
            color: #FF59CF;

            @include media(0, $mediaM) {
                margin-top: 1.3rem;
                font-size: 2.1rem;
            }
        }
    }

    &__socials {
        @extend %reset;
        @extend %col;
        margin-bottom: 3rem;

        @include media(0, $mediaM) {
            width: 80%;
            margin-bottom: 2rem;
            align-items: center;
        }

        &Support {
            @extend .field__support;

            @include media(0, $mediaM) {
                font-size: 2.2rem;
            }
        }

        &Items {
            @extend %reset;
            @extend %row;
            width: 100%;
            justify-content: center;
            align-items: center;

            @include media(0, $mediaM) {
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        &Item {
            @extend %reset;
            @extend %click;
            @extend %row;
            // width: calc(25% - 1rem);
            height: 5.8rem;
            border-radius: 1.5rem;
            padding: .2rem 1.5rem 0 1.5rem;
            border: .4rem solid $colorDark;
            justify-content: center;
            align-items: center;
            font-size: 1.9rem;
            color: $colorDark;
            text-transform: uppercase;
            background: #F3F3F3;
            @include button(.98);

            &+& {
                margin-left: 1rem;

                @include media(0, $mediaM) {
                    margin-left: 0;
                }
            }

            @include media(0, $mediaM) {
                width: calc(50% - .4rem);
                height: 6.5rem;
                font-size: 2.2rem;
                margin-bottom: .8rem;
            }

            &Icon {
                @extend %reset;
                width: 2.8rem;
                height: 2.8rem;
                margin-right: .7rem;
                margin-top: -.3rem;

                @include media(0, $mediaM) {
                    width: 2.8rem;
                    height: 2.8rem;
                }
            }
        }
    }

    &__codes {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;

        &Inputs {
            @extend %reset;
            position: relative;
            z-index: 1;
            margin-bottom: 2rem;

            &Loader {
                @extend %reset;
                @extend %loader;
                z-index: 10;
                will-change: transform;

                &:not(._show) {
                    transform: scale(1.03);
                }

                &Item {
                    @extend %reset;
                    @extend %loaderItem;
                    @include loaderColor($colorMain);
                }
            }

            &Inner {
                @extend %reset;
                @extend %row;
                align-items: center;
                transition: $trans;
                will-change: transform;
            }

            &._loading &Inner {
                transform: scale(.97);
            }
        }

        &Inputs._loading &Input {
            opacity: 0.5;
            pointer-events: none;
        }

        &Input {
            @extend %reset;
            width: 7.3rem;
            height: 7.3rem;
            margin: 0 .5rem;
            border-radius: 1.3rem;
            background: #F3F3F3;
            text-align: center;
            font-size: 3rem;
            font-weight: 500;
            color: $colorDark;
            transition: $trans;

            @include media(0, $mediaM) {
                width: 7rem;
                height: 7rem;
                font-size: 2.8rem;
                border-radius: 1.5rem;
                margin: 0 .4rem;
            }
        }
    }

    &__scan {
        @extend %reset;
        @extend %click;
        @extend %col;
        width: 100%;
        height: 23rem;
        border-radius: 1.5rem;
        background: #F3F3F3;
        position: relative;
        z-index: 1;
        justify-content: center;
        align-items: center;
        @include button(.98);

        &Back {
            @extend %reset;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;

            & .dashedBorder__rect {
                stroke: $colorDark;
            }
        }

        &:hover .dashedBorder__rect {
            animation: runStroke 7s infinite linear;
        }

        &Icon {
            @extend %reset;
            width: 4.5rem;
            height: 4.5rem;
            @include icon($colorDark);
            margin-bottom: 2rem;
        }

        &Description {
            @extend %reset;
            max-width: 100%;
            font-size: 2.3rem;
            line-height: 1.3;
            text-align: center;
            color: $colorDark;
        }
    }

    &__qrError {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
        padding: 2rem 0 4rem 0;

        &Title {
            @extend %reset;
            max-width: 100%;
            font-size: 2.1rem;
            font-weight: 500;
            color: $colorDark;
            margin-bottom: 1.5rem;
        }

        &Content {
            @extend %reset;
            margin-bottom: 4rem;
        }

        &Button {
            @extend %reset;
            height: 6.5rem;
        }
    }

    &__qrArea {
        @extend %reset;
        width: 100%;
        height: 40rem;
        border-radius: 1.5rem;
        background: #F3F3F3;
        margin-bottom: 2rem;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include media(0, $mediaM) {
            margin-bottom: 0;
        }

        &Loader {
            @extend %reset;
            @extend %loader;

            &Item {
                @extend %reset;
                @extend %loaderItem;
                width: 4rem;
                height: 4rem;
                @include loaderColor($colorDark);
            }
        }

        & canvas {
            @extend %reset;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__form {
        @extend %reset;
        width: 100%;
        padding-bottom: 1rem;

        &Fields {
            @extend %reset;
            @extend %row;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &Field {
            @extend %reset;
            width: calc(50% - 1.2rem);
            margin-bottom: 2.4rem;

            @include media(0, $mediaM) {
                width: 100%;
            }

            &._file {
                width: 100%;
            }

            &Support {
                @extend .field__support;
                font-size: 2rem;

                @include media(0, $mediaM) {
                    font-size: 2.3rem;
                }
            }

            &Content {
                @extend %reset;
                @extend %row;
                width: 100%;
                height: 6rem;
                justify-content: space-between;

                @include media(0, $mediaM) {
                    height: 6.5rem;
                }
            }

            &._file &Content {
                height: auto;
            }

            &Select {
                @extend %reset;
                width: calc(50% - .7rem);
                height: 100%;
            }
        }
    }

    &__foot {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
        margin-top: auto;
    }

    &__error {
        @extend %reset;

        &._parent {
            max-width: 100%;

            &._static {
                @extend %col;
            }
        }
    }

    &__buttons {
        @extend %reset;
        @extend %row;
        width: 100%;
        justify-content: center;
        align-items: center;

        &:not(._child) {
            margin-top: 3rem;
        }

        @include media(0, $mediaM) {
            flex-direction: column-reverse;
            justify-content: flex-start;
        }
    }

    &__buttons._dynamic &__button {
        white-space: nowrap;
        width: auto !important;
    }

    &__buttons._static &__button {
        width: auto !important;
    }

    &__button {
        @extend %reset;
        width: calc(50% - 1.5rem);
        height: 7rem;

        @include media(0, $mediaM) {
            height: 8rem;
        }

        &._auto {
            width: auto;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        @include media(0, $mediaM) {
            width: 100%;
        }

        &+& {
            margin-left: 1.5rem;

            @include media(0, $mediaM) {
                margin-left: 0;
                margin-bottom: 2.3rem;
            }
        }
    }

    &__success {
        @extend %reset;
        @extend %col;
        width: 100%;
        height: 23rem;
        justify-content: center;
        align-items: center;
        border-radius: 2.5rem;
        border: .4rem solid $colorDark;
        background: #93D500;
        padding: 3rem 5rem;
        margin: 2rem 0 3rem 0;

        &Description {
            @extend %reset;
            max-width: 100%;
            text-align: center;
            color: $colorDark;
            font-size: 2.4rem;
            font-weight: 500;
            line-height: 1.2;

            &+& {
                margin-top: 1rem;
            }
        }
    }

    &__order {
        @extend %reset;
        @extend %col;
        width: 100%;
        background: #FFD100;
        border-radius: 3rem;
        align-items: center;
        padding: 4rem 5rem 2rem 5rem;
        margin: -1rem 0 2.5rem 0;

        @include media(0, $mediaM) {
            border-radius: 2.5rem;
            padding: 2rem 3rem;
        }

        &Content {
            @extend %reset;
            width: 30rem;
            position: relative;

            @include media(0, $mediaM) {
                width: 33rem;
            }

            &Inner {
                @extend %reset;
                width: 100%;
                height: 30rem;
                border-radius: 3rem;
                border: .4rem solid $colorDark;
                background: #fff;
                position: relative;
                z-index: 1;
                overflow: hidden;

                @include media(0, $mediaM) {
                    height: 33rem;
                    border-radius: 4rem;
                }
            }
        }

        &Arrow {
            @extend %reset;
            position: absolute;
            top: 50%;
            width: 6rem;
            height: 6rem;
            z-index: 2;

            &._prev {
                left: 0;
                transform: translate(-50%, -50%);
            }

            &._next {
                right: 0;
                transform: translate(50%, -50%);
            }
        }

        &Items {
            @extend %reset;
            display: inline-flex;
            height: 100%;
        }

        &Item {
            @extend %reset;
            @extend %col;
            width: calc(30rem - .8rem);
            height: 100%;
            padding: 3rem;
            align-items: center;

            @include media(0, $mediaM) {
                width: calc(33rem - .8rem);
            }

            &Image {
                @extend %reset;
                width: 100%;
                height: 20rem;
                object-fit: contain;

                @include media(0, $mediaM) {
                    height: 22rem;
                }
            }

            &Name {
                @extend %reset;
                max-width: 100%;
                font-size: 2.3rem;
                text-align: center;
                font-weight: 500;
                color: $colorDark;
            }
        }

        &Info {
            @extend %reset;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            z-index: 2;
            border: .4rem solid $colorDark;
            padding: 1.2rem 2rem;
            border-radius: 36rem;
            background: #ED40A9;

            &Inner {
                @extend %reset;
                color: #fff;
                white-space: nowrap;
                font-weight: 500;
                font-size: 2rem;
            }
        }

        &Checkbox {
            @extend %reset;
            margin-top: 4.5rem;
        }

        &Success {
            @extend %reset;
            @extend %col;
            margin: 3.5rem 0;
            width: 100%;
            padding: 2.5rem 3rem;
            background: #ED40A9;
            border-radius: 3rem;
            border: .4rem solid $colorDark;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 2.4rem;
            line-height: 1.2;
            color: #fff;
            font-weight: 500;

            @include media(0, $mediaM) {
                margin: 6rem 0;
            }
        }
    }

    &__code {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
        margin: 3rem 0 2rem 0;

        &Support {
            @extend %reset;
            max-width: 100%;
            text-align: center;
            font-size: 2.5rem;
            line-height: 1.1;
            font-weight: 500;
            color: $colorDark;
            margin-bottom: 1rem;
        }

        &Input {
            @extend %reset;
            width: 100%;
            height: 8rem;
            border-radius: 1.5rem;
            border: .4rem solid $colorDark;
            background: #F3F3F3;
            font-size: 2.3rem;
            font-weight: 500;
            color: $colorDark;
            text-align: center;
        }

        &Success {
            @extend %reset;
            @extend %col;
            width: 100%;
            border-radius: 3rem;
            border: .4rem solid $colorDark;
            background: #93D500;
            padding: 2.5rem;
            align-items: center;
            margin: 3rem 0 3rem 0;

            &Text {
                @extend %reset;
                max-width: 100%;
                text-align: center;
                font-size: 2.3rem;
                line-height: 1.3;
                font-weight: 500;
                color: $colorDark;

                &+& {
                    margin-top: 1rem;
                }

                & a {
                    @extend %reset;
                    @extend %click;
                    display: inline-block;
                    line-height: 0.9;
                    font-weight: 500;
                    color: #DE261F;
                    border-bottom: 1px solid #DE261F;
                }
            }
        }
    }

    &__game {
        @extend %reset;
        width: 50rem;
        height: 50rem;
        border-radius: 3rem;
        border: .6rem solid $colorDark;
        position: relative;
        z-index: 1;
        overflow: hidden;
        margin-bottom: 3rem;

        @include media(0, $mediaM) {
            width: 100%;
            height: 45rem;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
            // @include bg('../media/quick-prize-back.png', cover, center);
            transition: $trans;
            will-change: transform;
        }

        &._start::before {
            transform: scale(1.05);
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: rgba(#000, .3);
            transition: $trans;
        }

        &:not(._complete)::after {
            opacity: 0;
        }

        &Loader {
            @extend %reset;
            @extend %loader;

            &Item {
                @extend %reset;
                @extend %loaderItem;
                @include loaderColor(#fff);
            }
        }

        &:not(._ready) &ButtonWrapper {
            opacity: 0;
            pointer-events: none;
        }

        &Button {
            @extend %reset;
            @extend %click;
            width: 100%;
            background: #fcfcfc;
            color: #DE261F;
            font-size: 2.4rem;
            line-height: 1.3;
            text-transform: uppercase;
            font-weight: 600;
            transition: $trans;
            padding: 2rem 1rem;
            border-radius: 36rem;
            text-align: center;
            @include button(.97);

            @include media(0, $mediaM) {
                font-size: 2.2rem;
                line-height: 1.2;
                padding: 1.5rem 1rem;
            }

            &Wrapper {
                @extend %reset;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                width: calc(100% - 6rem);
                transition: $trans;
                will-change: transform;

                @include media(0, $mediaM) {
                    width: calc(100% - 4rem);
                }
            }
        }

        &._start &ButtonWrapper {
            opacity: 0;
            transform: translate(-50%, -50%) scale(.8);
        }

        &:not(._complete) &PrizeSupport,
        &:not(._complete) &PrizeName {
            opacity: 0;
        }

        &:not(._complete) &PrizeSupport {
            margin-top: -1rem;
        }

        &:not(._complete) &PrizeName {
            transform: translate(0, 1rem);
        }

        &._complete &PrizeDecor {
            opacity: 0;
        }

        &Prize {
            @extend %reset;
            @extend %col;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 29rem;
            height: 29rem;
            z-index: 3;
            border-radius: 3.5rem;
            border: .4rem solid $colorDark;
            background: #fff;
            transition: $trans;
            will-change: transform;

            &Support {
                @extend %reset;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 2rem;
                padding: 1.3rem 2rem;
                white-space: nowrap;
                color: #fff;
                font-weight: 500;
                border-radius: 36rem;
                border: .4rem solid $colorDark;
                z-index: 3;
                background: #10CFC9;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }
            }

            &Name {
                @extend %reset;
                margin: auto auto 1.5rem auto;
                max-width: 90%;
                font-size: 2.2rem;
                line-height: 1.3;
                padding: 0 2rem;
                font-weight: 500;
                color: $colorDark;
                transition: $trans;
                text-align: center;
            }

            &Item {
                @extend %reset;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, calc(-50% - 2rem));
                width: 20rem;
                height: 20rem;
                object-fit: contain;
                transition: $trans;

                &:not(._current) {
                    opacity: 0;

                    &._1 {
                        margin: -.5rem 0 0 -.5rem;
                    }

                    &._2 {
                        margin: .5rem 0 0 .5rem;
                    }

                    &._3 {
                        margin: -.5rem 0 0 .5rem;
                    }

                    &._4 {
                        margin: .5rem 0 0 -.5rem;
                    }
                }
            }

            &Decor {
                @extend %reset;
                position: absolute;
                object-fit: contain;
                transition: $trans;

                &._1 {
                    width: 8.5rem;
                    height: 8.5rem;
                    top: -3.5rem;
                    left: 5rem;
                }

                &._2 {
                    width: 4rem;
                    height: 4rem;
                    top: 5rem;
                    left: 1.5rem;
                }

                &._3 {
                    width: 3.5rem;
                    height: 3.5rem;
                    bottom: 1.7rem;
                    right: 6.25rem;
                }

                &._4 {
                    width: 2.7rem;
                    height: 2.7rem;
                    bottom: 3.5rem;
                    right: 4rem;
                }

                &._5 {
                    width: 3rem;
                    height: 3rem;
                    bottom: 5.5rem;
                    right: 1.5rem;
                }
            }
        }

        &:not(._start) &Prize {
            opacity: 0;
            pointer-events: none;
            transform: translate(-50%, -50%) scale(1.1);
        }

        &._start &PrizeDecor {
            &._1 {
                @keyframes gameDecor1 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(0, -1.2rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                animation: .7s gameDecor1 infinite ease-in-out;
            }

            &._2 {
                @keyframes gameDecor2 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(-1.5rem, 0);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                animation: .7s gameDecor2 infinite ease-in-out;
                animation-delay: .2s;
            }

            &._3 {
                @keyframes gameDecor3 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(.2rem, 1rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                animation: .7s gameDecor3 infinite ease-in-out;
                // animation-delay: .2s;
            }

            &._4 {
                @keyframes gameDecor4 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(1rem, 1rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                animation: .7s gameDecor4 infinite ease-in-out;
            }

            &._5 {
                @keyframes gameDecor5 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(1rem, .5rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                animation: .7s gameDecor5 infinite ease-in-out;
                animation-delay: .1s;
            }
        }
    }

    &__prizeInfo {
        @extend %reset;
        max-width: 100%;
        padding: 0 3rem;
        text-align: center;
        font-size: 2rem;
        line-height: 1.3;
        color: $colorDark;
    }

    &__table {
        @extend %reset;
        width: 100%;
        position: relative;

        &Row {
            @extend %reset;
            @extend %row;
            width: 100%;
            padding: 0 2rem;
            align-items: stretch;
            border: .4rem solid transparent;

            @include media(0, $mediaM) {
                padding: 0 1rem;
            }

            &._head {
                border-color: $colorDark;
                background: #ED40A9;
                border-radius: 2.3rem;
                margin-bottom: 0.5rem;
            }

            &:not(._head) {
                border-radius: 1.5rem;

                &:nth-child(2n) {
                    background: #FFD100;
                }
            }
        }

        &Col {
            @extend %reset;
            @extend %col;
            padding: 1.3rem 1rem;
            line-height: 1.3;
            font-size: 2.3rem;
            font-weight: 500;
            color: $colorDark;
            text-align: center;
            justify-content: center;
            align-items: center;

            @include media(0, $mediaM) {
                font-size: 2.2rem;
            }

            &._negative {
                color: #ED40A9;
            }

            &._date {
                width: 27%;

                @include media(0, $mediaM) {
                    width: 30%;
                }
            }

            &._counter {
                width: 25%;
            }

            &._comment {
                width: 48%;

                @include media(0, $mediaM) {
                    width: 45%;
                }
            }
        }

        &Content {
            @extend %reset;
            width: 100%;
            max-height: 42rem;
            border-radius: 2.3rem;
            border: .4rem solid $colorDark;
            padding: 2rem;
            overflow-y: auto;

            @include media(0, $mediaM) {
                padding: 1rem;
            }
        }

        &Content &Row {
            padding: 0;
        }
    }

    &__regInfo {
        @extend %reset;
        background: #FF59CF;
        color: #fff;
        border-radius: 36rem;
        padding: .8rem 1.5rem .7rem 1.5rem;
        font-size: 1.9rem;
        font-weight: 600;
        line-height: 1.3;
        text-align: center;
    }

    &__foot._afterLogin &__error:not(._empty) {
        margin-top: 2rem;
    }

    &__confirm {
        @extend %reset;
        width: 100%;
        transition: $trans;
        position: relative;

        &Block {
            @extend %reset;
            @extend %col;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: $trans;
            align-items: center;
            padding-top: 3rem;

            &:not(._show) {
                opacity: 0;

                &._prev {
                    transform: translate(-5rem, 0);
                }

                &._next {
                    transform: translate(5rem, 0);
                }
            }
        }

        &._static &Block {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }

        &Password {
            @extend %reset;
            @extend %col;
            width: 100%;
            align-items: center;
            margin-bottom: 3rem;

            &Title {
                @extend %reset;
                max-width: 100%;
                text-align: center;
                color: #A8278E;
                font-weight: 600;
                font-size: 2.2rem;
                margin-bottom: 1.5rem;
            }

            &Box {
                @extend %reset;
                @extend %row;
                width: 33rem;
                height: 7rem;
                border-radius: 1.5rem;
                border: .4rem solid #F3F3F3;
                justify-content: center;
                align-items: center;
                transition: $trans;
                margin-bottom: 1.3rem;

                &Copy {
                    @extend %reset;
                    @extend %click;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: inline-block;
                    transform: translate(0, .4rem);
                    margin-left: .7rem;
                }
            }

            &Inner {
                @extend %row;
                justify-content: center;
                width: 100%;
                font-size: 2.8rem;
                color: $colorDark;
                font-weight: 600;
                transition: $trans;
            }

            &._copySuccess &Inner {
                color: #FF59CF;
            }

            &Support {
                @extend %reset;
                max-width: 100%;
                font-size: 2rem;
                line-height: 1.3;
                text-align: center;
                color: $colorDark;

                @include media(0, $mediaM) {
                    max-width: 90%;
                }
            }
        }

        &Button {
            @extend %reset;
        }
    }

    &__cupon {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
        padding-bottom: 3.5rem;

        &Code {
            @extend %reset;
            width: 100%;
            height: 15rem;
            object-fit: contain;
            margin-bottom: 3rem;
        }

        &Description {
            @extend %reset;
            max-width: 100%;
            text-align: center;
            font-size: 1.9rem;
            line-height: 1.3;
            color: $colorDark;
            margin-bottom: 2rem;

            @include media(0, $mediaM) {
                max-width: 90%;
                font-size: 2.1rem;
            }
        }

        &Info {
            @extend %reset;
            width: 100%;
            border-radius: 1.5rem;
            background: #F4F4F4;
            padding: 2.5rem;
            font-size: 1.45rem;
            line-height: 1.35;
            color: $colorDark;

            @include media(0, $mediaM) {
                font-size: 1.5rem;
                border-radius: 1.8rem;
            }
        }
    }
}