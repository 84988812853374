.cookies {
    @extend %reset;
    @extend %col;
    width: 100%;
    padding: 3rem 3rem 2.5rem 3rem;
    border-radius: 3rem;
    background: #fff;
    align-items: center;

    &__content {
        @extend %reset;
        max-width: 89%;
        text-align: center;
        font-size: 1.9rem;
        line-height: 1.3;
        color: $colorDark;
        margin-bottom: 1.5rem;

        @include media(0, $mediaM) {
            max-width: 100%;
            font-size: 1.95rem;
        }
    }

    &__buttons {
        @extend %reset;
        @extend %row;
        align-items: center;
        margin-bottom: 2.5rem;

        @include media(0, $mediaM) {
            width: 100%;
            flex-direction: column;
        }
    }

    &__button {
        @extend %reset;
        width: 21rem;

        & .button__content {
            font-size: 1.8rem !important;

            @include media(0, $mediaM) {
                font-size: 2.1rem !important;
            }
        }

        @include media(0, $mediaM) {
            width: 100%;
        }

        &+& {
            margin-left: 1rem;

            @include media(0, $mediaM) {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
    }

    &__link {
        @extend %reset;
        @extend %click;
        font-size: 1.9rem;
        color: $colorMain;
        border-bottom: 1px solid rgba($colorMain, .6);

        @include media(0, $mediaM) {
            font-size: 2.2rem;
        }
    }
}